import React, {useState} from 'react';
import {Button, Form, Input, Typography} from 'antd';
import {Link} from 'react-router-dom';

const Login = () => {
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        console.log('Received values:', values);
        // Implement Firebase authentication logic here
    };

    return (
        <main className="w-full max-w-xs grid place-items-center mx-auto">
            <Form
                name="basic"
                initialValues={{remember: true}}
                onFinish={onFinish}
                className={"w-full"}
            >
                <Typography.Title>Login</Typography.Title>
                <Form.Item
                    name="username"
                    rules={[{required: true, message: 'Please input your username!'}]}
                >
                    <Input placeholder="Username"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{required: true, message: 'Please input your password!'}]}
                >
                    <Input.Password placeholder="Password"/>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="w-full"
                        loading={loading}
                    >
                        Log in
                    </Button>
                </Form.Item>
                <Link to="/forgot-password">Forgot Password?</Link>
            </Form>
        </main>
    );
};

export default Login;
