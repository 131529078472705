import {Link, useLocation} from "react-router-dom";
import {Avatar} from "antd";

const navigation = [
    {name: 'Dashboard', href: 'dashboard', current: false},
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Example() {
    const location = useLocation();

    // Update the 'current' field based on the current pathname
    const updatedNavigation = navigation.map((item) => ({
        ...item,
        current: location.pathname.includes(item.href),
    }));

    return (
        <header className="relative flex items-center justify-between shadow">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                    <Link to="/" className="text-lg font-bold">Timely</Link>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                        {updatedNavigation.map((item) => (
                            <Link
                                key={item.name}
                                to={item.href}
                                className={classNames(
                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'rounded-md px-3 py-2 text-sm font-medium'
                                )}
                                aria-current={item.current ? 'page' : undefined}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex items-center">
                <Avatar style={{backgroundColor: '#1890ff'}}>JD</Avatar>
            </div>
        </header>
    );
}
