import {createSlice} from '@reduxjs/toolkit'

// AGENCY DETAILS THAT ARE BEING VIEWED ON ROUTE -> /agency/:id and /agency/:id/ts/:tsID
const employeeSlice = createSlice({
    name: 'employee',
    initialState: {
        data: null
    },
    reducers: {
        setEmployeeSliceData: (state, action) => {
            state.data = action.payload
        },
        clearEmployeeSliceData: state => {
            state.data = null
        }
    }
})

export const {setEmployeeSliceData, clearEmployeeSliceData} = employeeSlice.actions
export default employeeSlice.reducer;
