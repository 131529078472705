import React, {useEffect} from 'react';
import {Button, Space, Table} from 'antd';
import CandidateModal from "./CandidateModal.component"

const CandidateTable = (props) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space>
                    <CandidateModal type="edit" formData={record} employee={false} reFetchData={props.fetchData}/>
                    <Button>Delete</Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        props.fetchData();
    }, []);

    return (
        <Table
            columns={columns}
            dataSource={props.data}
            loading={props.loading}
            className='h-full'
            scroll={{y: 420}}
        />
    );
};

export default CandidateTable;