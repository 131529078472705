import React from 'react';
import {Form, Input, Button, Typography} from 'antd';

const ForgotPassword = () => {
    const onFinish = (values) => {
        console.log('Received values:', values);
        // Implement Firebase forgot password logic here
    };

    return (
        <main className="w-full max-w-xs mx-auto mt-20">
            <Typography.Title>Forgot Password</Typography.Title>
            <Form name="basic" onFinish={onFinish}>
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: 'Please input your email!' },
                        { type: 'email', message: 'Please enter a valid email!' },
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="w-full">
                        Send Reset Email
                    </Button>
                </Form.Item>
            </Form>
        </main>
    );
};

export default ForgotPassword;
