import React, {useEffect, useState} from 'react';
import {Button, Drawer, Form, notification, Select} from 'antd';
import {createTimesheet, updateTimesheet} from "../Firebase/services/timesheets";
import {useForm} from "antd/es/form/Form";
import dayjs from "dayjs";
import {useSelector} from "react-redux";

import updateLocale from 'dayjs/plugin/updateLocale';
import {_getMonthLabel, _replaceUndefinedKeys, MONTH_OPTIONS} from "../utils";
import {Loader, PdfDrawer} from "./index";
import TimeSheetForms from "./TimeSheetForms.component";
import CandidateSearchDrawer from "./CandidateSearch.component";
import {doc} from "firebase/firestore";
import {db} from "../Firebase";

dayjs.extend(updateLocale);
dayjs.updateLocale('en-us');

// todo: update UI to start from sunday and end on saturday
// todo: ADD LOGIC FOR INVOICE STATUS
// todo: remove date of submission and date of creation, add into a popup when adding new timesheet
// todo: add logic to search if timesheet of that candidate for that month exists and use that to prefill the data  

const TimeSheetDrawerComponent = ({type, data, reFetchData, showAddEmployee}) => {
    const [drawerVisibility, setDrawerVisibility] = useState({
        timesheet: false,
        candidate: false
    })
    const {data: rdxEmployeeSliceData} = useSelector(state => state.employee)   // agency data in store that's being viewed
    const [isLoading, setLoading] = useState(false);
    const [FormInstance] = useForm();
    const [daysInMonth, setDaysInMonth] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(dayjs().month());

    useEffect(() => {
        const getDaysInMonth = () => {
            const days = [];
            let startDate = dayjs().month(selectedMonth).startOf('month');
            let endDate = dayjs().month(selectedMonth).endOf('month');

            // Adjust startDate if timesheet month is same as projectStartDate month

            let currentDate = startDate;
            while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
                days.push(currentDate.clone());
                currentDate = currentDate.add(1, 'day');
            }
            return days;
        };

        setDaysInMonth(getDaysInMonth());
        FormInstance?.setFieldsValue({monthOfTs: dayjs().month(selectedMonth).format("MM/YYYY")});

    }, [selectedMonth]);

    const handleSelectCandidate = (candidate) => {
        FormInstance.setFieldsValue({employeeName: candidate.name})
    };

    const handleClearData = () => {
        FormInstance.resetFields();
    };

    const handleSaveData = () => {
        FormInstance.submit();
    };
    let configProps = {
        btnTitle: "+ Add New Timesheet",
        modalTitle: "Create Timesheet",
        onFormSubmit: (form) => {
            setLoading(true)
            const {values, forms} = form
            let updatedValues = _replaceUndefinedKeys(values)
            let approvalDate = values["approvalDate"].toISOString()
            let creationDate = values["creationDate"].toISOString()
            updatedValues = {
                ...updatedValues,
                employeeRef: doc(db, "employees", rdxEmployeeSliceData["id"]),
                employeeRefId: rdxEmployeeSliceData["id"],
                approvalDate,
                creationDate,
                noOfWeeks: Object.keys(forms).length - 1,
                month: selectedMonth,
                monthLabel: _getMonthLabel(selectedMonth)
            }
            let config = {type: "success", message: "Timesheet created successfully"}
            createTimesheet(updatedValues)
                .catch((error) => {
                    config = {
                        type: "error", message: "Timesheet couldn't be created. Please try again"
                    }
                    console.error(error)
                })
                .finally(() => {
                    setLoading(false)
                    notification[config.type]({message: config.message})
                    if (config.type === "success") {
                        handleCancel(true)
                    }
                })
        },
    }

    if (type === 'edit') {
        configProps = {
            ...configProps,
            btnTitle: "Edit",
            modalTitle: "Edit Timesheet Details",
            onFormSubmit: (form) => {
                setLoading(true)
                let config = {type: "success", message: "Timesheet updated successfully"}
                const {values, forms} = form
                let updatedValues = _replaceUndefinedKeys(values)
                let approvalDate = values["approvalDate"].toISOString()
                let creationDate = values["creationDate"].toISOString()
                updatedValues = {
                    ...updatedValues,
                    approvalDate,
                    creationDate,
                    noOfWeeks: Object.keys(forms).length - 1,
                    month: selectedMonth,
                    monthLabel: _getMonthLabel(selectedMonth)
                }

                setLoading(true)
                updateTimesheet(data.id, updatedValues)
                    .catch((err) => {
                        config = {
                            type: "error", message: "Timesheet couldn't be updated. Please try again"
                        }
                        console.error(err)
                    })
                    .finally(() => {
                        setLoading(false)
                        notification[config.type]({message: config.message})
                        if (config.type === "success") {
                            handleCancel(true)
                        }
                    })
            },
        }
    }
    const showDrawer = () => {
        if (type === "edit") {
            setLoading(true)
            const {employeeRef, days, fileUrl, ...rest} = data
            let formData = Object.keys(rest).map(key => {
                if (["approvalDate", "creationDate"].includes(key)) {
                    return {
                        [key]: dayjs(rest[key])
                    }
                }
                return {
                    [key]: rest[key]
                }
            })
            formData = formData.reduce((result, obj) => ({...result, ...obj}), {})
            setSelectedMonth(formData.month)
            FormInstance.setFieldsValue(formData)
            setLoading(false)
        }
        if (type === "add") {
            FormInstance.setFieldsValue({employeeName: rdxEmployeeSliceData["candidate"]["name"]})
            FormInstance.setFieldsValue({reportingManager: rdxEmployeeSliceData["reportingManager"]})
            FormInstance.setFieldsValue({reportingEmail: rdxEmployeeSliceData["reportingEmail"]})
            FormInstance.setFieldsValue({cid: rdxEmployeeSliceData["cid"]})
            FormInstance.setFieldsValue({endClientName: rdxEmployeeSliceData["endClientName"]})
            FormInstance.setFieldsValue({endClientLocation: rdxEmployeeSliceData["endClientLocation"]})
        }
        setDrawerVisibility(prevState => ({...prevState, timesheet: true}))
    };

    const handleCancel = (refetch = false) => {
        setDrawerVisibility(prevState => ({...prevState, timesheet: false}))
        FormInstance.resetFields()
        handleClearData()
        if (refetch) {
            reFetchData()
        }
    };


    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                {configProps.btnTitle}
            </Button>
            <Drawer
                title={<div className={"flex flex-row justify-between pr-4 items-center"}>
                    {configProps.modalTitle}
                    <div className={"grid grid-cols-2 items-center gap-x-2"}>
                        {
                            type === 'add' && showAddEmployee && <Button
                                className={"my-2"} type="primary"
                                onClick={() => setDrawerVisibility(prevState => ({
                                    ...prevState,
                                    candidate: true
                                }))}>
                                + Add Employee
                            </Button>
                        }
                        <Select
                            disabled={type === "edit"}
                            options={MONTH_OPTIONS}
                            value={selectedMonth}
                            onChange={setSelectedMonth}
                        />
                    </div>
                </div>}
                open={drawerVisibility.timesheet}
                onClose={() => handleCancel(false)}
                width={"100%"}
                destroyOnClose={true}
                footer={
                    <div className={"flex flex-row gap-2 my-4"}>
                        <Button onClick={handleSaveData} type="primary">Save Data</Button>
                        <Button danger onClick={()=>handleCancel(false)}>Cancel</Button>
                        {data && <PdfDrawer data={data} reFetchData={reFetchData}/>}
                    </div>
                }
                classNames={{body: "relative"}}
            >
                <Loader isLoading={isLoading}/>
                <Form.Provider onFormFinish={(_, values) => configProps.onFormSubmit(values)}>
                    <TimeSheetForms daysInMonth={daysInMonth} FormInstance={FormInstance}/>
                </Form.Provider>
                <CandidateSearchDrawer
                    visible={drawerVisibility.candidate}
                    onClose={() => setDrawerVisibility(prevState => ({...prevState, candidate: false}))}
                    onSelectCandidate={handleSelectCandidate}
                />
            </Drawer>
        </>
    );
};
export default TimeSheetDrawerComponent;

TimeSheetDrawerComponent.defaultProps = {
    type: "add",
    data: null,
    showAddEmployee: false,
    mode: "monthly",
}