import {db} from '../index';
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";

export const createAgency = async (agencyData) => {
    try {
        const docRef = await addDoc(collection(db, 'agencies'), {...agencyData, employeeCount:0});
        return docRef.id;
    } catch (error) {
        console.error('Error creating agency:', error);
        throw new Error('Failed to create agency');
    }
};

export const updateAgency = async (agencyId, updatedData) => {
    try {
        await updateDoc(doc(db, 'agencies', agencyId), updatedData);
    } catch (error) {
        console.error('Error updating agency:', error);
        throw new Error('Failed to update agency');
    }
};

export const deleteAgency = async (agencyId) => {
    try {
        await deleteDoc(doc(db, 'agencies', agencyId));
    } catch (error) {
        console.error('Error deleting agency:', error);
        throw new Error('Failed to delete agency');
    }
};

export const getAgencyDetails = async (agencyId) => {
    try {
        const docRef = doc(db, 'agencies', agencyId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            return {id: agencyId, ...docSnapshot.data()};
        } else {
            throw new Error('Agency not found');
        }
    } catch (error) {
        console.error('Error fetching agency details:', error);
        throw new Error('Failed to fetch agency details');
    }
};

export const getAllAgencyDetails = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'agencies'));
        const agencies = [];
        querySnapshot.forEach((doc) => {
            agencies.push({id: doc.id, ...doc.data()});
        });
        return agencies;
    } catch (error) {
        console.error('Error fetching agencies:', error);
        throw new Error('Failed to fetch agencies');
    }
};
