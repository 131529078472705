import {db} from '../index';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    query,
    updateDoc,
    where
} from "firebase/firestore";
import {getCandidateDetails} from "./candidates";

export const addEmployeeToAgency = async (docData) => {
    try {
        // Add the employee to the employees collection
        const employeeDocRef = await addDoc(collection(db, 'employees'), docData);
        // Increment the employeeCount field in the agencies collection
        const agencyDocRef = doc(db, 'agencies', docData.agencyRefId);
        await updateDoc(agencyDocRef, {
            employeeCount: increment(1)
        });

        return Promise.resolve(employeeDocRef.id);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const updateEmployee = (docId, updatedData) => updateDoc(doc(db, 'employees', docId), updatedData);

export const deleteEmployeForAgency = async (docId) => {
    try {
        await deleteDoc(doc(db, 'employees', docId));
        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error)
    }
};

export const getEmployeeDetails = async (docId) => {
    try {
        const docRef = doc(db, 'employees', docId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            let docData = {id: docSnapshot.id, ...docSnapshot.data()};
            let candidate = await getCandidateDetails(docData.candidateRefId);
            docData = {
                ...docData,
                candidate,
            };
            return Promise.resolve(docData);
        } else {
            return Promise.reject()
        }
    } catch (error) {
        return Promise.reject(error)
    }
};

export const getAllEmployeesForAgency = async (agencyRefId) => {
    try {
        let q = query(
            collection(db, "employees"),
            where("agencyRefId", "==", agencyRefId)
        );
        if (agencyRefId === "all") {
            q = collection(db, "employees");
        }
        const querySnapshot = await getDocs(q);
        const agencies = [];

        for (const doc of querySnapshot.docs) {
            let docData = {id: doc.id, ...doc.data()};
            let candidate = await getCandidateDetails(docData.candidateRefId);
            docData = {
                ...docData,
                candidate,
            };
            agencies.push(docData);
        }
        return Promise.resolve(agencies);
    } catch (error) {
        return Promise.reject(error)
    }
};
