import React, {useEffect} from 'react';
import {Button, Table} from 'antd';
import EmployeeDrawer from "./EmployeeDrawer.component";
import {setEmployeeSliceData} from "../store/employee.slice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {_genEmployeeLink} from "../utils";

const EmployeesTable = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const goToEmployeePage = (record) => {
        let employeePageUrl = _genEmployeeLink(record.id)
        dispatch(setEmployeeSliceData(record))
        navigate(employeePageUrl)
    }
    const columns = [
        {
            title: "ID",
            dataIndex: "cid",
            key: "cid"
        },
        {
            title: 'Candidate Name',
            dataIndex: 'candidate.name',
            key: 'candidate.name',
            render: (_, record) => <span>{record.candidate.name}</span>

        },
        {
            title: 'Candidate Email',
            dataIndex: 'candidate.email',
            key: 'candidate.email',
            render: (_, record) => <span>{record.candidate.email}</span>
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            width: 100,
            render: (_, record) => <span>${record.rate}/hr</span>
        }, {
            title: 'Invoice Terms',
            dataIndex: 'invoiceTerms',
            key: 'invoiceTerms',
            width: 150
        }, {
            title: 'Payment Terms',
            dataIndex: 'paymentTerms',
            key: 'paymentTerms',
            width: 150
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className={"flex gap-2"}>
                    <Button onClick={() => goToEmployeePage(record)}>View</Button>
                    <EmployeeDrawer type={"edit"} formData={record} reFetchData={props.fetchData}/>
                </div>
            ),
            width: 200
        },
    ];

    useEffect(() => {
        props.fetchData && props.fetchData();
    }, []);

    return (
        <Table
            columns={columns}
            dataSource={props.data}
            loading={props.loading}
            className='h-full'
            scroll={{y: 420}}
        />
    );
};

export default EmployeesTable;