import {configureStore} from '@reduxjs/toolkit'
import agencySlice from "./agency.slice"
import employeeSlice from "./employee.slice";

const store = configureStore({
    reducer: {
        "agency": agencySlice,
        "employee": employeeSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActionPaths: ['payload.agencyRef', 'payload.candidateRef','employee.data.agencyRef','employee.data.candidateRef'],
                ignoredPaths: ['payload.agencyRef', 'payload.candidateRef','employee.data.agencyRef','employee.data.candidateRef'],
            },
        }),
})

export default store