import React, {useEffect, useState} from 'react';
import {Button, notification, Table} from 'antd';
import {PdfDrawer, TimeSheetDrawer} from "./index";
import {deleteTimesheet, getAllTimeSheetsForEmployee} from "../Firebase/services/timesheets";
import {useSelector} from "react-redux";
import {getInvoiceDetails} from "../Firebase/services/invoice";
import InvoiceCard from "../components/InvoiceCard.components";
import ConfirmationPopover from "./ConfirmationPopover.component";

export default (props) => {
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const {data: rdxEmployeeSliceData} = useSelector(state => state.employee)   // agency data in store that's being viewed
    const [expandedRowKey, setExpandedRowKey] = useState()
    const [isFetchingInvoiceData, setIsFetchingInvoiceData] = useState(true)
    const getMonth = (date = "03/2024") => {
        return parseInt(date.split("/")[0])
    }
    const columns = [
        {
            title: "Month",
            dataIndex: "monthOfTs",
            key: "monthOfTs",
            width: 100,
            defaultSortOrder: 'descend',
            sorter: (a, b) => getMonth(a.monthOfTs) - getMonth(b.monthOfTs)
        },
        {
            title: "Regular Hours",
            dataIndex: "totalRegHrs",
            key: "totalRegHrs",
            width: 100
        },
        {
            title: "Extra Hours",
            dataIndex: "totalExtraHrs",
            key: "totalExtraHrs",
            width: 100
        },
        {
            title: "Total Hours",
            dataIndex: "totalHrs",
            key: "totalHrs",
            render: (meta, record) => {
                return <Button
                    type={"link"}
                    onClick={() => {
                        setExpandedRowKey((currentExpandedKey) => {
                            if (currentExpandedKey === record.id) {
                                return null
                            }
                            return record.id
                        })
                    }}
                >
                    {record?.totalHrs}
                </Button>
            },
            width: 100
        },
        {
            title: 'Action',
            key: 'action',
            width: 250,
            render: (_, record) => (
                <div className='flex gap-2'>
                    <TimeSheetDrawer
                        type={"edit"}
                        reFetchData={props.fetchData ? props.fetchData() : fetchTimeSheetsData}
                        data={record}
                    />
                    <PdfDrawer reFetchData={props.fetchData ? props.fetchData() : fetchTimeSheetsData} data={record}/>
                    <DeleteTimeSheet reFetchData={props.fetchData ? props.fetchData() : fetchTimeSheetsData}
                                     data={record}/>
                </div>
            ),
        },
    ];

    const fetchTimeSheetsData = async () => {
        setLoading(true);
        try {
            const data = await getAllTimeSheetsForEmployee(rdxEmployeeSliceData.id)
            setTableData(data)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching agency data:', error);
            setLoading(false);
        }
    };

    if (props.refetchTableDataRef) {
        props.refetchTableDataRef.current = fetchTimeSheetsData
    }

    useEffect(() => {
        props.fetchData ? props.fetchData() : fetchTimeSheetsData()
    }, []);

    const [invoiceData, setInvoiceData] = useState(null);
    useEffect(() => {
        if (Boolean(expandedRowKey)) {
            getInvoiceDetails(expandedRowKey)
                .then(data => setInvoiceData(data))
                .finally(() => setIsFetchingInvoiceData(false))
        }
    }, [expandedRowKey]);

    return (
        <Table
            rowKey={record => record.id}
            columns={columns}
            dataSource={props?.data || tableData}
            loading={props?.loading || loading}
            className='h-full'
            expandable={{
                expandIconColumnIndex: -1,
                expandedRowKeys: [expandedRowKey],
                expandedRowRender: (rowData, index) => <InvoiceCard
                    record={{
                        ...rowData,
                        count: props?.data?.length || tableData?.length
                    }}
                    setInvoiceData={setInvoiceData}
                    invoiceData={invoiceData}
                    isFetching={isFetchingInvoiceData}
                    setIsFetching={setIsFetchingInvoiceData}
                />,
                rowExpandable: record => record["totalHrs"] > 0
            }}
            scroll={{y: window.innerHeight * 0.7}}
        />
    );
};

const DeleteTimeSheet = ({data, reFetchData}) => {
    const [loading, setLoading] = useState(false)
    let config = {type: "success", message: "Timesheet deleted successfully"}

    const onClick = () => {
        deleteTimesheet(data.id)
            .catch((error) => {
                config = {
                    type: "error", message: "Timesheet couldn't be deleted. Please try again"
                }
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
                notification[config.type]({message: config.message})
                if (config.type === "success") {
                    reFetchData(true)
                }
            })
    }

    return <ConfirmationPopover onOk={onClick} placement={"topLeft"}>
        <Button danger loading={loading}>Delete</Button>
    </ConfirmationPopover>
}