import React, {useState} from 'react';
import {Tabs} from 'antd';
import CandidateModal from "../../components/CandidateModal.component"
import AgencyButton from "../../components/AgencyModal.component"
import CandidateTable from "../../components/CandidateTable.component"
import AgencyTable from "../../components/AgencyTable.component"
import {getAllAgencyDetails} from '../../Firebase/services/agencies';
import {getAllCandidateDetails} from '../../Firebase/services/candidates'; // Importing the async function to fetch data

const App = () => {

    const [activeTab, setActiveTab] = useState('2')
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const onChange = (key) => {
        setActiveTab(key);
        setData([])
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            let func = activeTab === '1' ? getAllCandidateDetails : getAllAgencyDetails
            const data = await func()
            setData(data)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching agency data:', error);
            setLoading(false);
        }
    };

    const slot = {
        right: activeTab === '1'
            ? <CandidateModal type="add" reFetchData={fetchData} employee={false}/>
            : <AgencyButton type="add" reFetchData={fetchData}/>,
    };

    const items = [
        {
            key: '2',
            label: 'Agencies',
            children: <AgencyTable
                fetchData={fetchData}
                loading={loading}
                data={data}
            />,
        },
        {
            key: '1',
            label: 'Candiates',
            children: <CandidateTable
                fetchData={fetchData}
                loading={loading}
                data={data}
            />,
        },
    ];


    return <Tabs
        rootClassName={"flex-1"}
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        items={items}
        onChange={onChange}
        tabBarExtraContent={slot}
        destroyInactiveTabPane={true}
    />
};
export default App;