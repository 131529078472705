import {db} from '../index';
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";


const invoicesCollection = collection(db, "invoices")

export const createInvoice = async (candidateData, cb) => {
    try {
        const docRef = await addDoc(invoicesCollection, candidateData)
        cb && cb()
        return docRef.id;
    } catch (error) {
        console.error('Error creating candidate:', error);
        throw new Error('Failed to create candidate');
    }
};

export const updateInvoice = async (candidateId, updatedData, cb) => {
    try {
        await updateDoc(doc(db, 'invoices', candidateId), updatedData)
        cb && cb()
    } catch (error) {
        console.error('Error updating candidate:', error);
        throw new Error('Failed to update candidate');
    }
};

export const deleteInvoice = async (candidateId) => {
    try {
        await deleteDoc(doc(db, 'invoices', candidateId));
    } catch (error) {
        console.error('Error deleting agency:', error);
        throw new Error('Failed to delete candidate');
    }
};

export const getInvoiceDetails = async (docID) => {
    try {
        const docRef = doc(db, "invoices", docID);
        const docSnapshot = await getDoc(docRef);
        return docSnapshot.exists() ? docSnapshot.data() : null;
    } catch (error) {
        console.error('Error fetching candidate details:', error);
        throw new Error('Failed to fetch candidate details');
    }
};

export const getAllInvoiceDetails = async () => {
    try {
        const querySnapshot = await getDocs(invoicesCollection)
        const invoices = [];
        querySnapshot.forEach((doc) => {
            invoices.push({id: doc.id, ...doc.data()});
        });
        return invoices;
    } catch (error) {
        console.error('Error fetching invoices:', error);
        throw new Error('Failed to fetch invoices');
    }
};
