import {getAllCandidateDetails} from "../Firebase/services/candidates";
import {Button, Drawer, Form, Input, Radio} from "antd";
import {useEffect, useState} from "react";


// TODO: ADD SPINNER FOR LOADING
// TODO: ADD SEARCH BY EMAIL LOGIC
// TODO: REFACTOR UI
const CandidateSearchDrawer = ({visible, onClose, onSelectCandidate}) => {
    const [allCandidates, setAllCandidates] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [searchEmail, setSearchEmail] = useState('');

    useEffect(() => {
        // Fetch all candidates on modal open
        const fetchData = async () => {
            const data = (await getAllCandidateDetails()).map(d => ({
                ...d,
                label: `${d.name} | ${d.email}`,
            }));
            setAllCandidates(data);
        };
        if (visible) {
            fetchData();
        }
    }, [visible]);

    const handleCandidateSelectChange = (candidate) => {
        setSelectedCandidate(candidate);
    };

    const handleSearch = () => {
        // Perform search with searchEmail
        // Set search results to allCandidates
    };

    const handleFormSubmit = () => {
        onSelectCandidate(selectedCandidate);
        onClose();
    };

    return (
        <Drawer
            title="Search Candidates"
            placement="right"
            onClose={onClose}
            open={visible}
            width={"35%"}
        >
            <Form.Item label="Search by Email">
                <Input value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)}/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleSearch}>Search</Button>
            </Form.Item>
            <Form.Item label="Select Candidate">
                <Radio.Group onChange={(e) => handleCandidateSelectChange(e.target.value)}>
                    {allCandidates.map(candidate => (
                        <Radio key={candidate.id} value={candidate}>{candidate.label}</Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleFormSubmit}>Select Candidate</Button>
            </Form.Item>
        </Drawer>
    );
};

export default CandidateSearchDrawer