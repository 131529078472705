import {createBrowserRouter, Navigate, RouterProvider,} from "react-router-dom";
import Root from "./Outlet.jsx";
import Dashboard from "./pages/Dashboard";
import Agency from "./pages/Agency/ManageEmployees";
import ManageEmployees from "./pages/Employees/ManageEmployees";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";


const router = createBrowserRouter([
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword/>
    },
    {
        path: "/",
        element: <Root/>,
        children: [
            {
                path: "/", // Match both "/" and "/dashboard"
                element: <Navigate to={"/dashboard"}/>,
            },
            {
                path: "/dashboard", // Match both "/" and "/dashboard"
                element: <Dashboard/>,
            },
            {
                path: "/agency/:id", // Match both "/" and "/dashboard"
                element: <Agency/>,
            },
            {
                path: "emp/:id",
                element: <ManageEmployees/>
            },
        ],
        errorElement: <div className={"grid h-full place-items-center"}>
            Oops! Something went wrong <br/>
            Please refresh page
        </div>
    },
]);


export default () => {
    return <RouterProvider router={router}/>;
};
