import React, {useEffect, useState} from 'react';
import {Button, Card, notification, Table} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setAgencySliceData} from "../store/agency.slice";
import {_extractLastThreeDigits, _genAgencyPageLink} from "../utils";
import EmployeesTable from "./EmployeesTable.component";
import {getAllEmployeesForAgency} from "../Firebase/services/employees";
import AgencyButton from "./AgencyModal.component";
import ConfirmationPopover from "../components/ConfirmationPopover.component";
import {deleteAgency} from "../Firebase/services/agencies";

const AgenciesTable = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [expandedRowKey, setExpandedRowKey] = useState()

    useEffect(() => {
        props.fetchData();
    }, []);

    useEffect(() => {
        if (Boolean(expandedRowKey)) {
            fetchListOfEmployees(expandedRowKey)
        }
    }, [expandedRowKey]);

    const onClick = (record) => {
        dispatch(setAgencySliceData(record))
        navigate(_genAgencyPageLink(record.id))
    }
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchListOfEmployees = async (id) => {
        setTableData([])
        setLoading(true)
        try {
            const data = await getAllEmployeesForAgency(id)
            setTableData(data)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching agency data:', error);
            setLoading(false);
        }
    };
    const columns = [
        {
            title: 'Agency Name',
            dataIndex: 'agencyName',
            key: 'agencyName',
            render: (_, record) => <Link to={_genAgencyPageLink(record.id)}>{record.agencyName}</Link>,
        },
        {
            title: 'SPOC Name',
            render: (_, record) => {
                return <div>{record.spocs?.length > 0 ? record.spocs[0].spocName : "No spocs added"}</div>
            },
        },
        {
            title: 'Agency Code',
            dataIndex: 'agencyCode',
            key: 'agencyCode',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => _extractLastThreeDigits(a.agencyCode) - _extractLastThreeDigits(b.agencyCode)
        },
        {
            title: 'Number of employees added',
            dataIndex: 'employeeCount',
            key: 'employeeCount',
            render: (meta, record) => {
                return <Button
                    type={"link"}
                    onClick={() => {
                        setExpandedRowKey((currentExpandedKey) => {
                            if (currentExpandedKey === record.id) {
                                return null
                            }
                            return record.id
                        })
                    }}
                >
                    {record?.employeeCount || "No employee added"}
                </Button>
            },
            width: 250
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className='w-full flex flex-row gap-2'>
                    <AgencyButton type={"edit"} formData={record} reFetchData={props.fetchData}/>
                    <Button onClick={() => onClick(record)}>View</Button>
                    <DeleteAgency data={record} reFetchData={props.fetchData}/>
                </div>
            ),
        },
    ];

    return (
        <Table
            scroll={{y: window.innerHeight * 0.7}}
            rowKey={record => record.id}
            columns={columns}
            dataSource={props.data}
            loading={props.loading}
            className='h-full'
            expandable={{
                expandIconColumnIndex: -1,
                expandedRowKeys: [expandedRowKey],
                expandedRowRender: (record) => {

                    dispatch(setAgencySliceData(record))

                    return <Card title={"List of Employees for " + record.agencyName}>
                        <EmployeesTable
                            loading={loading}
                            data={tableData}
                            fetchData={() => fetchListOfEmployees(record.id)}
                        />
                    </Card>
                },
                rowExpandable: (record) => record?.employeeCount > 0,
            }}
        />
    );
};

export default AgenciesTable;

const DeleteAgency = ({data, reFetchData}) => {
    const [loading, setLoading] = useState(false)
    let config = {type: "success", message: "Agency deleted successfully"}

    const onClick = () => {
        deleteAgency(data.id)
            .catch((error) => {
                config = {
                    type: "error", message: "Agency couldn't be deleted. Please try again"
                }
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
                notification[config.type]({message: config.message})
                if (config.type === "success") {
                    reFetchData(true)
                }
            })
    }

    return <ConfirmationPopover
        onOk={onClick} placement={"topLeft"}
    >
        <Button danger loading={loading}>Delete</Button>
    </ConfirmationPopover>
}