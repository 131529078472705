import {DatePicker, Divider, Form, Input, Select} from "antd";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {_calculateHours, HOURS_OPTIONS} from "../utils";
const TimeSheetFormsComponent = ({FormInstance, daysInMonth}) => {

    return (
        <>
            <Form
                form={FormInstance}
                name={"timesheet-details"}
                className={"p-2 border rounded-xl grid grid-cols-6 gap-2 form-item-margin"}
                layout={"vertical"}
            >
                <Form.Item
                    label={"Month"}
                    name="monthOfTs"
                    rules={[{required: true, message: "Can't be empty"}]}
                >
                    <Input disabled={true}/>
                </Form.Item>

                <Form.Item
                    label={"Employee"}
                    name="employeeName"
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label={"Consultant Unique ID"}
                    name="cid"
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Project"
                    name="endClientName"
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Project Location"
                    name="endClientLocation"
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="First Level Approval"
                    name="reportingManager"
                >
                    <Input disabled={true}/>
                </Form.Item>

                <Form.Item
                    label="Approver Email"
                    name="reportingEmail"
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Total Regular Hours"
                    name="totalRegHrs"
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Total Overtime Hours"
                    name="totalExtraHrs"
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Total Hours"
                    name="totalHrs"
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Date of Submission"
                    name="creationDate"
                    initialValue={dayjs()}
                    rules={[{required: true, message: 'Please input the name of agency!'}]}
                >
                    <DatePicker format={"MM/DD/YYYY"}/>
                </Form.Item>
                <Form.Item
                    label="Date of Approval"
                    name="approvalDate"
                    initialValue={dayjs()}
                >
                    <DatePicker format={"MM/DD/YYYY"}/>
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    rules={[{required: true, message: 'Please input the name of agency!'}]}
                    initialValue={"Applied"}
                >
                    <Select
                        options={[{label: 'Applied', value: 'Applied'}, {label: "Approved", value: "Approved"}]}/>
                </Form.Item>
            </Form>
            <MonthlyTimeSheetDrawer daysInMonth={daysInMonth} FormInstance={FormInstance}/>
        </>
    )
};

const MonthlyTimeSheetDrawer = ({daysInMonth, FormInstance}) => {
    // Split days into weeks
    const weeks = [];
    let currentWeek = [];
    daysInMonth.forEach((day, index) => {
        currentWeek.push(day);
        if ((index + 1) % 7 === 0 || index === daysInMonth.length - 1) {
            weeks.push(currentWeek);
            currentWeek = [];
        }
    });
    return (
        <div className={"border rounded-xl p-4 mt-4"}>
            {weeks.map((weekDays, index) => (
                <WeeklyTimeSheet
                    FormInstance={FormInstance}
                    key={index}
                    count={index}
                    weekDays={weekDays}
                />
            ))}
        </div>
    );
};
const WeeklyTimeSheet = ({weekDays, FormInstance, count}) => {
    const {data: rdxEmployeeSliceData} = useSelector(state => state.employee)   // agency data in store that's being viewed
    const projectStartDate = dayjs(rdxEmployeeSliceData["projectStartDate"])
    const beforeProjectStateDate = (date) => date.isBefore(projectStartDate)

    return (
        <Form
            form={FormInstance}
            name={`form_${count}`}
            className={"grid grid-cols-7 gap-x-2"}
            onValuesChange={(_, allValues) => {
                const daysOfWeekRegex = /_/i;
                const timesheetData = {};

                for (const key in allValues) {
                    if (daysOfWeekRegex.test(key)) {
                        timesheetData[key] = allValues[key];
                    }
                }
                _calculateHours(timesheetData, FormInstance)
            }}
        >
            {weekDays.map((date) => {
                return <Form.Item
                    label={
                        <div className={"col-span-2"}>
                            <span className={"text-[#1677ff]"}>{date.format('MMM, DD, ddd')}</span>
                        </div>
                    }
                    name={date.format('DD_ddd').toLowerCase()}
                    key={date.format('DD_ddd').toLowerCase()}
                    className={"item-center divider-2"}
                >
                    <Select
                        popupMatchSelectWidth={false}
                        className={"option-center !w-2/4"}
                        suffixIcon={null}
                        options={HOURS_OPTIONS}
                        placeholder={"hrs"}
                        showSearch={true}
                        disabled={beforeProjectStateDate(date)}
                    />
                </Form.Item>
            })}
            <Divider className={"col-span-full !mt-0"}/>
        </Form>
    );
};


export default TimeSheetFormsComponent