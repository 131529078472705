import React, {useState} from 'react';
import {Button, Form, Modal, notification} from 'antd';
import AgencyForm from "./AgencyForm.component"
import {createAgency, updateAgency} from "../Firebase/services/agencies";

const AgencyButton = ({type, formData, reFetchData}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    let configProps = {
        btnTitle: "+ Add Agency",
        modalTitle: "Add Agency",
        onFormSubmit: (values) => {
            setLoading(true)
            let config = {type: "success", message: "Agency added successfully"}

            createAgency(values)
                .catch((err) => {
                    config = {
                        type: "error", message: "Agency couldn't be updated. Please try again"
                    }
                    console.error(err)
                })
                .finally(() => {
                    setLoading(false)
                    notification[config.type]({message: config.message})
                    if (config.type === "success") {
                        handleCancel(true)
                    }
                })
        },
    }

    if (type === 'edit') {
        configProps = {
            ...configProps,
            btnTitle: "Edit Agency",
            modalTitle: "Edit Agency",
            formData: formData,
            onFormSubmit: (values) => {
                setLoading(true)
                let config = {type: "success", message: "Agency updated successfully"}
                updateAgency(formData.id, values)
                    .catch((err) => {
                        config = {
                            type: "error", message: "Agency couldn't be updated. Please try again"
                        }
                        console.error(err)
                    })
                    .finally(() => {
                        setLoading(false)
                        notification[config.type]({message: config.message})
                        if (config.type === "success") {
                            handleCancel(true)
                        }
                    })
            }
        }
    }
    const showModal = () => {
        setIsModalOpen(true);
        form.setFieldsValue(formData)
    };
    const handleOk = () => {
        form.submit()
    };
    const handleCancel = (refetch) => {
        setIsModalOpen(false);
        form.resetFields()
        refetch && reFetchData()
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                {configProps.btnTitle}
            </Button>
            <Modal
                title={configProps.modalTitle}
                open={isModalOpen}
                onOk={handleOk}
                okButtonProps={{loading}}
                onCancel={() => handleCancel()}
                width={"80vw"}
                centered={true}
            >
                <AgencyForm
                    form={form}
                    onFormSubmit={configProps.onFormSubmit}
                />
            </Modal>
        </>
    );
};
export default AgencyButton;

AgencyButton.defaultProps = {
    type: "add",
    formData: null
}