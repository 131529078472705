import React, {useState} from 'react';
import {Button, DatePicker, Form, Input, InputNumber, Select} from 'antd';
import {_replaceUndefinedKeys, invoiceTermsOptions, paymentTermsOptions, validateMessages} from "../utils";
import CandidateSearchDrawer from "./CandidateSearch.component";
import {doc} from "firebase/firestore";
import {db} from "../Firebase";
import {useSelector} from "react-redux"; // Importing list items from utils

// TODO: add logic for generation of unique ids automatically

const EmployeeDetailsForm = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const {data: rdxAgencySliceData} = useSelector(state => state.agency)   // agency data in store that's being viewed

    const handleOpenModal = () => {
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const handleSelectCandidate = (candidate) => {
        props.setSelectedCandidate(candidate);
    };

    return (
        <>
            <Form
                validateMessages={validateMessages}
                name={'EmployeeDetailsForm'}
                form={props.form}
                initialValues={{remember: true}}
                onFinish={(values) => {
                    let projectStartDate = undefined
                    if (values.projectStartDate) {
                        projectStartDate = values.projectStartDate.toISOString()
                    }
                    props.onFormSubmit({
                        ..._replaceUndefinedKeys(values),
                        projectStartDate,
                        candidateRef: doc(db, "candidates", props.selectedCandidate.id),
                        candidateRefId: props.selectedCandidate.id,
                        agencyRef: doc(db, "agencies", rdxAgencySliceData.id),
                        agencyRefId: rdxAgencySliceData.id,
                        agencyCode: rdxAgencySliceData.agencyCode,
                    })
                }}
                layout='vertical'
                className='grid grid-cols-2 gap-x-2'
            >
                <Form.Item rootClassName={"!mb-2"} label={"Selected Employee Details"} className={'col-span-2'}>
                    <div className="grid grid-cols-2 gap-x-2">
                        <Input value={props.selectedCandidate ? props.selectedCandidate.id : ''} disabled/>
                        <Input value={props.selectedCandidate ? props.selectedCandidate.email : ''} disabled/>
                    </div>
                    {
                        props.showActionBtn &&
                        <Button className={"w-full my-2"} type="primary" onClick={handleOpenModal}>
                            + Add Employee
                        </Button>
                    }
                </Form.Item>
                <Form.Item label="Consultant Unique ID"
                           name="cid"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Start Date of Project"
                           name="projectStartDate"
                           rules={[{required: true}]}>
                    <DatePicker format={"MM/DD/YYYY"}/>
                </Form.Item>
                <Form.Item label="Rate" name="rate"
                           rules={[{required: true}]}>
                    <InputNumber prefix={"$"} className={"w-full"} suffix={"/hr"} controls={false}/>
                </Form.Item>
                <Form.Item label="OT Rate" name="extraRate"
                           rules={[{required: true}]}>
                    <InputNumber prefix={"$"} className={"w-full"} suffix={"/hr"} controls={false}/>
                </Form.Item>

                <Form.Item label="Payment Terms" name="paymentTerms"
                           rules={[{required: true}]}>
                    <Select options={paymentTermsOptions} placeholder={"Select payment terms"}/>
                </Form.Item>

                <Form.Item label="Invoice Terms"
                           name="invoiceTerms"
                           rules={[{required: true}]}>
                    <Select options={invoiceTermsOptions}
                            placeholder={"Select invoice terms"}/>
                </Form.Item>

                <Form.Item label="Reporting Manager"
                           name="reportingManager"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Reporting Manager Email"
                           name="reportingEmail"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="End Client"
                           name="endClientName"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="End Client Location"
                           name="endClientLocation"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Work Authorization"
                    name="workAuth"
                    rules={[{required: true}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Role"
                    name="role"
                    rules={[{required: true}]}
                >
                    <Input/>
                </Form.Item>
            </Form>
            <CandidateSearchDrawer
                visible={modalVisible}
                onClose={handleCloseModal}
                onSelectCandidate={handleSelectCandidate}
            />
        </>
    );
};

EmployeeDetailsForm.defaulProps = {
    showActionBtn: true
}
export default EmployeeDetailsForm;
