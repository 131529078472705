import axios from "axios"

const http = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Set your base URL here
    headers: {
        'Content-Type': 'application/json', // Set your default headers here
    }
});

export default http

const generatePdf = (docId) => http.post("/ts/pdf/", {docId})
const generateInvoice = data => http.post("/ts/invoice/", data)

export const _Services = {
    generatePdf,
    generateInvoice
}