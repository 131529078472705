import dayjs from "dayjs";

export const _replaceUndefinedKeys = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(_replaceUndefinedKeys);
    }

    return Object.fromEntries(
        Object.entries(obj)
            .map(([key, value]) => [key, _replaceUndefinedKeys(value)])
            .map(([key, value]) => [key, value !== undefined ? value : null]) // Change undefined to null
    );
};

export const invoiceTermsOptions = [
    {value: "7", label: "Weekly"},
    {value: "15", label: "Bi-Weekly"},
    {value: "month", label: "Monthly"},
]
export const paymentTermsOptions = [
    {value: "10", label: "Net 10"},
    {value: "15", label: "Net 15"},
    {value: "30", label: "Net 30"},
    {value: "45", label: "Net 45"},
    {value: "60", label: "Net 60"},
    {value: "90", label: "Net 90"},
]
export const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "'${label}' is required!",
};
export const isoStringToDayJs = (isoDateString) => dayjs(isoDateString)
export const HOURS_OPTIONS = [
    {label: "00", value: 0},
    {label: "01", value: 1},
    {label: "02", value: 2},
    {label: "03", value: 3},
    {label: "04", value: 4},
    {label: "05", value: 5},
    {label: "06", value: 6},
    {label: "07", value: 7},
    {label: "08", value: 8},
    {label: "09", value: 9},
    {label: "10", value: 10},
    {label: "11", value: 11},
    {label: "12", value: 12},
    {label: "14", value: 14},
    {label: "16", value: 16},
    {label: "18", value: 18},
];
export const MONTH_OPTIONS = [
    {label: "January", value: 0},
    {label: "February", value: 1},
    {label: "March", value: 2},
    {label: "April", value: 3},
    {label: "May", value: 4},
    {label: "June", value: 5},
    {label: "July", value: 6},
    {label: "August", value: 7},
    {label: "September", value: 8},
    {label: "October", value: 9},
    {label: "November", value: 10},
    {label: "December", value: 11}
];
export const _genEmployeeLink = (id) => "/emp/" + id
export const _genAgencyPageLink = (id) => "/agency/" + id

export function _calculateHours(data, FormInstance) {
    let totalHrs = 0;
    let totalRegHrs = 0;
    let totalExtraHrs = 0;

    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            let [_, day] = key.split('_');
            let hours = data[key] === undefined ? 0 : data[key];
            totalHrs += hours;

            if (['sat', 'sun'].includes(day)) { // Weekend
                totalExtraHrs += hours
            } else { // Weekday
                if (hours > 8) {
                    totalRegHrs += 8;
                    totalExtraHrs += Math.max(0, hours - 8); // Extra hours above 8
                } else {
                    totalRegHrs += hours;
                }
            }
        }
    }
    FormInstance.setFieldsValue({
        totalHrs, totalRegHrs, totalExtraHrs
    })

    // return [totalHrs, totalRegHrs, totalExtraHrs];
}

export const STATES_LIST = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware",
    "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
    "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
    "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico",
    "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania",
    "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont",
    "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

export const _extractLastThreeDigits = str => parseInt(str.slice(-3));

export const _getMonthLabel = (index) => {
    return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ][index]
}

export function generateInvoiceNumber(num) {
    // Convert number to string
    const numStr = num.toString();
    // Calculate the number of leading zeros needed
    const numZeros = 4 - numStr.length;
    // Add leading zeros using padStart
    return '#' + '0'.repeat(numZeros) + numStr;
}

export function addDaysToDate(dateIsoStr, days) {
    const result = new Date(dateIsoStr);
    result.setDate(result.getDate() + days);
    return result;
}

export function formatPhoneNumber(numString) {
    // Extract the three parts of the phone number
    let areaCode = numString.substring(0, 3);
    let firstPart = numString.substring(3, 6);
    let secondPart = numString.substring(6);

    // Format the phone number with parentheses and spaces
    return `(${areaCode}) ${firstPart} ${secondPart}`;
}