import React, {useEffect, useState} from 'react';
import {Card, Spin} from 'antd';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getAllEmployeesForAgency} from "../../Firebase/services/employees";
import {getAgencyDetails} from "../../Firebase/services/agencies";
import {setAgencySliceData} from "../../store/agency.slice";
import EmployeeDrawer from "../../components/EmployeeDrawer.component";
import EmployeesTable from "../../components/EmployeesTable.component";

const AgencyPage = () => {
    const [isFetching, setIsFetching] = useState(true)
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const {data: rdxAgencySliceData} = useSelector(state => state.agency)   // agency data in store that's being viewed
    const dispatch = useDispatch()
    const urlParams = useParams();
    const fetchData = async () => {
        setLoading(true);
        try {
            const data = await getAllEmployeesForAgency(rdxAgencySliceData.id)
            setTableData(data)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching agency data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (urlParams && rdxAgencySliceData === null) {
            getAgencyDetails(urlParams.id)
                .then((data) => {
                    dispatch(setAgencySliceData(data))
                })
                .finally(() => {
                    setIsFetching(false)
                })
        } else {
            setIsFetching(false)
        }
    }, []);

    return <>
        {
            isFetching
                ? <Spin className={"grid place-items-center h-full"}/>
                : <main className='grid grid-cols-12 gap-x-4'>
                    <Card className='col-span-2' title="Agency Details">
                        <p><strong>Name</strong><br/> {rdxAgencySliceData["agencyName"]}</p>
                        <p><strong>Agency Code</strong><br/> {rdxAgencySliceData["agencyCode"]}</p>
                        <p><strong>Address</strong><br/>
                           {rdxAgencySliceData["registeredAddressLine1"]}{", "}
                           {rdxAgencySliceData["registeredAddressLine2"]}{", "}
                           {rdxAgencySliceData["registeredState"]}{", "}
                           {rdxAgencySliceData["registeredZipCode"]}
                        </p>
                    </Card>
                    <Card
                        title={(
                            <div className="flex flex-row justify-between items-center">
                                <h2>Employees</h2>
                                <EmployeeDrawer
                                    type={"add"}
                                    agencyRef={rdxAgencySliceData.id}
                                    reFetchData={fetchData}
                                />
                            </div>
                        )}
                        className='col-span-10'
                    >
                        <EmployeesTable
                            fetchData={fetchData}
                            loading={loading}
                            data={tableData}
                        />
                    </Card>
                </main>
        }
    </>
};

export default AgencyPage;