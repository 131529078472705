import React, {useRef, useState} from 'react';
import {Button, Checkbox, Form, Input, InputNumber, Select} from 'antd';
import {_replaceUndefinedKeys, STATES_LIST} from "../utils";

const {TextArea} = Input;
const {Option} = Select;

const AgencyForm = (props) => {
    const [sameAsRegistered, setSameAsRegistered] = useState(false);
    const addFieldRef = useRef(null);

    const onCheckboxChange = (e) => {
        setSameAsRegistered(e.target.checked);
        if (e.target.checked) {
            props.form.setFieldsValue({
                mailingAddressLine1: props.form.getFieldValue('registeredAddressLine1'),
                mailingAddressLine2: props.form.getFieldValue('registeredAddressLine2'),
                mailingState: props.form.getFieldValue('registeredState'),
                mailingZipCode: props.form.getFieldValue('registeredZipCode')
            });
        }
    };

    const formItemStyle = {className: "flex-1"}
    return (
        <Form
            form={props.form}
            name="agency_form"
            initialValues={{creationDate: null, sameAsRegistered: sameAsRegistered}}
            onFinish={(values) => props.onFormSubmit(_replaceUndefinedKeys(values))}
            layout='vertical'
        >
            <div className='grid grid-cols-2 gap-2'>
                <Form.Item
                    label="Name of Agency"
                    name="agencyName"
                    rules={[{required: true, message: 'Please input the name of the agency!'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item label={"Agency Code"} name="agencyCode" required={true}>
                    <Input/>
                </Form.Item>
            </div>
            <div className='grid grid-cols-2 gap-2'>
                <Form.Item label="Registered Address">
                    <Form.Item
                        name="registeredAddressLine1"
                        rules={[{required: true, message: 'Please input address line 1!'}]}
                    >
                        <Input placeholder="Address Line 1"/>
                    </Form.Item>
                    <Form.Item name="registeredAddressLine2">
                        <Input placeholder="Address Line 2 (Optional)"/>
                    </Form.Item>
                    <Form.Item
                        name="registeredState"
                        rules={[{required: true, message: 'Please select a state!'}]}
                    >
                        <Select placeholder="Select State" showSearch={true}>
                            {STATES_LIST.map(state => (
                                <Option key={state} value={state}>{state}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="registeredZipCode"
                        rules={[{required: true, message: 'Please input the zip code!'}]}
                    >
                        <Input placeholder="Zip Code"/>
                    </Form.Item>
                </Form.Item>

                <div className='relative'>
                    <Form.Item label="Mailing Address">
                        <Form.Item
                            name="mailingAddressLine1"
                            rules={[{required: true, message: 'Please input address line 1!'}]}
                        >
                            <Input placeholder="Address Line 1"/>
                        </Form.Item>
                        <Form.Item name="mailingAddressLine2">
                            <Input placeholder="Address Line 2 (Optional)"/>
                        </Form.Item>
                        <Form.Item
                            name="mailingState"
                            rules={[{required: true, message: 'Please select a state!'}]}
                        >
                            <Select placeholder="Select State">
                                {STATES_LIST.map(state => (
                                    <Option key={state} value={state}>{state}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="mailingZipCode"
                            rules={[{required: true, message: 'Please input the zip code!'}]}
                        >
                            <Input placeholder="Zip Code"/>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item
                        name="sameAsRegistered"
                        valuePropName="checked"
                        className='absolute top-0 right-0 pb-[8px]'>
                        <Checkbox onChange={onCheckboxChange}>Same as registered address</Checkbox>
                    </Form.Item>
                </div>
            </div>

            <Form.List name="spocs">
                {(fields, {add, remove}) => {
                    if (!addFieldRef.current) {
                        addFieldRef.current = add
                    }
                    return <>
                        {fields.map(({key, name, fieldKey, ...restField}) => (
                            <div key={key} className='flex items-center gap-2'>
                                <Form.Item
                                    {...formItemStyle}
                                    label={`#${name + 1} SPOC Name`}
                                    name={[name, 'spocName']}
                                    rules={[{required: true, message: 'Please input the SPOC name!'}]}
                                    {...restField}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    {...formItemStyle}
                                    label={`#${name + 1} SPOC Email`}
                                    name={[name, 'spocEmail']}
                                    rules={[{required: true, type: 'email', message: 'Invalid email address!'}]}
                                    {...restField}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    {...formItemStyle}
                                    label={`#${name + 1} SPOC Contact Number`}
                                    name={[name, 'spocContactNumber']}
                                    rules={[{
                                        required: true,
                                        pattern: /^\d{10}$/,
                                        message: 'Please enter a valid US phone number!'
                                    }]}
                                    {...restField}
                                >
                                    <InputNumber controls={false} style={{width: '100%'}}/>
                                </Form.Item>

                                {/* Add SPOC Type dropdown */}
                                <Form.Item
                                    {...formItemStyle}
                                    label={`#${name + 1} SPOC Type`}
                                    name={[name, 'spocType']}
                                    {...restField}
                                >
                                    <Select placeholder="Select SPOC Type">
                                        <Option value="recruiter">Recruiter</Option>
                                        <Option value="manager">Manager</Option>
                                        <Option value="accounts">Accounts</Option>
                                        <Option value="finance">Finance</Option>
                                    </Select>
                                </Form.Item>
                                <span onClick={() => remove(name)}
                                      className={"cursor-pointer hover:text-red-600"}>X</span>
                            </div>
                        ))}

                    </>
                }}
            </Form.List>
            <Button className={"ml-auto"} onClick={() => addFieldRef.current && addFieldRef.current()}>
                Add SPOC
            </Button>
            <Form.Item
                label="Remarks"
                name="remarks"
            >
                <TextArea rows={4}/>
            </Form.Item>
        </Form>
    );
};

export default AgencyForm;
