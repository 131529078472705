import React, {useState} from 'react';
import {Button, Drawer, Form, notification} from 'antd';
import EmployeeDetailsForm from "./EmployeeForm.components";
import {addEmployeeToAgency, updateEmployee} from "../Firebase/services/employees";
import {_genEmployeeLink, isoStringToDayJs} from "../utils";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setEmployeeSliceData} from "../store/employee.slice";

const EmployeeDrawer = (props) => {
    const {type, formData} = props
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let employeePageUrl = _genEmployeeLink(formData?.id)
    const goToEmployeePage = () => {
        dispatch(setEmployeeSliceData(formData))
        navigate(employeePageUrl)
    }

    let configProps = {
        btnTitle: "+ Add New Employee",
        modalTitle: "Add New Employee to agency",
        onFormSubmit: (values) => {
            setLoading(true)
            let config = {type: "success", message: "Employee added successfully"}
            addEmployeeToAgency(values)
                .catch((error) => {
                    config = {
                        type: "error", message: "Employee couldn't be created. Please try again"
                    }
                    console.error(error)
                })
                .finally(() => {
                    setLoading(false)
                    notification[config.type]({message: config.message})
                    if (config.type === "success") {
                        props?.reFetchData()
                        handleCancel(true)
                    }
                })
        },
    }

    if (type === 'edit') {
        configProps = {
            ...configProps,
            btnTitle: "Edit",
            modalTitle: "Edit Employee Details",
            formData: formData,
            onFormSubmit: (values) => {
                setLoading(true)
                let config = {type: "success", message: "Employee updated successfully"}
                updateEmployee(formData.id, values)
                    .catch((err) => {
                        config = {
                            type: "error", message: "Employee couldn't be updated. Please try again"
                        }
                        console.error(err)
                    })
                    .finally(() => {
                        setLoading(false)
                        notification[config.type]({message: config.message})
                        if (config.type === "success") {
                            handleCancel({refetch: true})
                        }
                    })
            },
        }
    }
    const showModal = () => {
        if (type === "edit") {
            let projectStartDate = isoStringToDayJs(formData.projectStartDate);
            setSelectedCandidate({...formData.candidate, id: formData.candidateRefId})
            form.setFieldsValue({...formData, projectStartDate})
        }
        setIsModalOpen(true);
    };


    const handleCancel = ({refetch = false}) => {
        setIsModalOpen(false);
        form.resetFields()
        setSelectedCandidate(null)
        if (refetch) {
            props?.reFetchData()
        }
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                {configProps.btnTitle}
            </Button>
            <Drawer
                title={configProps.modalTitle}
                open={isModalOpen}
                onClose={handleCancel}
                width={"40%"}
                destroyOnClose={true}
            >
                <EmployeeDetailsForm
                    form={form}
                    onFormSubmit={configProps.onFormSubmit}
                    selectedCandidate={selectedCandidate}
                    setSelectedCandidate={setSelectedCandidate}
                    showActionBtn={type === 'add'}
                />
                <div className="flex justify-between">
                    <Button disabled={!Boolean(selectedCandidate)}
                            loading={loading}
                            type={"primary"}
                            onClick={() => form.submit()}>
                        Submit
                    </Button>
                    {type === 'edit' && <Button onClick={goToEmployeePage}>View</Button>}
                </div>
            </Drawer>
        </>
    );
};
export default EmployeeDrawer;

EmployeeDrawer.defaultProps = {
    type: "add",
    formData: null
}