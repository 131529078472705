import {db} from '../index';
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";


const candidatesCollection = collection(db, "candidates")

export const createCandidate = async (candidateData, cb) => {
    try {
        const docRef = await addDoc(candidatesCollection, candidateData)
        cb && cb()
        return docRef.id;
    } catch (error) {
        console.error('Error creating candidate:', error);
        throw new Error('Failed to create candidate');
    }
};

export const updateCandidate = async (candidateId, updatedData, cb) => {
    try {
        await updateDoc(doc(db, 'candidates', candidateId), updatedData)
        cb && cb()
    } catch (error) {
        console.error('Error updating candidate:', error);
        throw new Error('Failed to update candidate');
    }
};

export const deleteCandidate = async (candidateId) => {
    try {
        await deleteDoc(doc(db, 'candidates', candidateId));
    } catch (error) {
        console.error('Error deleting agency:', error);
        throw new Error('Failed to delete candidate');
    }
};

export const getCandidateDetails = async (candidateId) => {
    try {
        const docRef = doc(db, "candidates", candidateId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists) {
            return docSnapshot.data();
        } else {
            throw new Error('Candidate not found');
        }
    } catch (error) {
        console.error('Error fetching candidate details:', error);
        throw new Error('Failed to fetch candidate details');
    }
};

export const getAllCandidateDetails = async () => {
    try {
        const querySnapshot = await getDocs(candidatesCollection)
        const candidates = [];
        querySnapshot.forEach((doc) => {
            candidates.push({id: doc.id, ...doc.data()});
        });
        return candidates;
    } catch (error) {
        console.error('Error fetching candidates:', error);
        throw new Error('Failed to fetch candidates');
    }
};
