import React, {useEffect, useState} from 'react';
import {Button, Card, Descriptions, Drawer, notification, Spin, Typography} from "antd";
import {addDaysToDate, formatPhoneNumber, generateInvoiceNumber} from "../utils";
import {useDispatch, useSelector} from "react-redux";
import {getAgencyDetails} from "../Firebase/services/agencies";
import {setAgencySliceData} from "../store/agency.slice";
import {_Services} from "../services";
import {getInvoiceDetails} from "../Firebase/services/invoice";

const InvoiceCard = ({record, isFetching, invoiceData, setInvoiceData, setIsFetching}) => {
    const {totalHrs, totalExtraHrs, totalRegHrs} = record;
    const {data: rdxEmployeeSliceData} = useSelector(state => state.employee)   // agency data in store that's being viewed
    const {rate, extraRate} = rdxEmployeeSliceData
    const totalPayout = (rate * totalRegHrs) + (totalExtraHrs * extraRate)

    useEffect(() => {
        getInvoiceDetails(record.id)
            .then(data => setInvoiceData(data))
            .finally(() => setIsFetching(false))
    }, [record.id]);

    if (isFetching) {
        return <Spin className={"grid place-items-center h-full"}/>
    }
    return <Card title={"Invoice Details"}>
        <Descriptions
            bordered
            items={[
                {
                    label: "Total Hours",
                    key: 1,
                    children: totalHrs
                },
                {
                    label: "Total Regular Hours",
                    key: 2,
                    children: totalRegHrs
                },
                {
                    label: "Total Extra Hours",
                    key: 3,
                    children: totalExtraHrs
                },
                {
                    label: "Rate",
                    key: 4,
                    children: rate
                },
                {
                    label: "Overtime Rate",
                    key: 6,
                    children: extraRate
                },
                {
                    label: "Total Payout",
                    key: 5,
                    children:
                        <Typography.Title>{`$${totalPayout}`}</Typography.Title>
                },
            ]}
        />
        <div className="mt-4 grid place-content-end grid-cols-2 gap-x-4">
            <GenerateInvoicePDF
                data={{
                    monthLabel: record.monthLabel,
                    timesheetRefId: record.id,
                    rate: rate.toFixed(2),
                    extraRate: extraRate.toFixed(2),
                    totalRegHrs,
                    totalExtraHrs,
                    totalHrs,
                    regularHrAmt: (totalRegHrs * rate).toFixed(2),
                    overtimeHrAmt: (totalExtraHrs * extraRate).toFixed(2),
                    totalAmt: totalPayout.toFixed(2),
                    invoiceNo: generateInvoiceNumber(record.count)
                }}
                setInvoiceData={setInvoiceData}
                invoiceData={invoiceData}
            />
            {
                invoiceData &&
                <ViewInvoicePDFDrawer
                    data={invoiceData}
                />
            }
        </div>
    </Card>
};

const GenerateInvoicePDF = ({data, invoiceData, setInvoiceData}) => {
    const {data: agency} = useSelector(state => state.agency)   // agency data in store that's being viewed
    const {data: employee} = useSelector(state => state.employee)   // agency data in store that's being viewed
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!agency) {
            getAgencyDetails(employee.agencyRefId)
                .then(data => {
                    dispatch(setAgencySliceData(data))
                })
                .catch()
                .finally()
        }
    }, [])

    const onClick = () => {
        let creationTimeStamp = (new Date()).toISOString()

        let apiBody = {
            ...data,
            candidateRefId: employee.candidateRefId,
            agencyRefId: agency.id,
            employeeRefId: employee.id,
            cd: creationTimeStamp,
            dd: addDaysToDate(creationTimeStamp, parseInt(employee["paymentTerms"])).toISOString(),
            "fromCompanyName": employee.candidate.candidateOnCompany,
            "fromFullAdrs": employee.candidate.address,
            "fromPhone": formatPhoneNumber(employee.candidate.phone),
            "fromName": employee.candidate.name,
            "toCompanyName": agency.agencyName,
            "toAdrs1": agency.registeredAddressLine1,
            "toAdrs2": agency.registeredAddressLine2,
            "toState": agency.registeredState,
            "toZip": agency.registeredZipCode,
            "toCountry": "USA",
        }
        let config = {type: "success", message: "Invoice generated successfully"}
        setLoading(true)
        _Services.generateInvoice(apiBody)
            .then((data) => {
                setInvoiceData({
                    ...invoiceData,
                    data
                })
            })
            .catch((err) => {
                config = {
                    type: "error", message: "Invoice couldn't be generated. Please try again"
                }
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
                notification[config.type]({message: config.message})
            })
    }

    return <Button onClick={onClick} loading={loading} disabled={loading}>Generate Invoice PDF</Button>
}

const ViewInvoicePDFDrawer = ({data}) => {
    const [open, setOpen] = useState(false)

    return <>
        <Button onClick={() => setOpen(true)}>View PDF</Button>
        <Drawer
            open={open}
            title="Invoice PDf"
            placement="right"
            onClose={() => setOpen(false)}
            width={"50vw"}
        >
            <embed
                src={data.fileUrl}
                className={"h-full w-full"}
            />
        </Drawer>
    </>

}

export default InvoiceCard;