import {getFirestore} from "firebase/firestore";
import {initializeApp,} from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyAcyfSKUF2_kfzbchdtRChwi1UKUVm1wgI",
    authDomain: "timely-59678.firebaseapp.com",
    projectId: "timely-59678",
    storageBucket: "timely-59678.appspot.com",
    messagingSenderId: "1034559324487",
    appId: "1:1034559324487:web:50be9ba99aaf934c984c62",
    measurementId: "G-66B9CNN63N"
};

const app = initializeApp(firebaseConfig, {
    ignoreUndefinedProperties: true
});
export const db = getFirestore(app)