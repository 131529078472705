import React, {useEffect, useRef, useState} from 'react';
import {Card, Spin} from 'antd';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {TimeSheetDrawer, TimeSheetsTable} from "../../components";
import dayjs from "dayjs";
import {getEmployeeDetails} from "../../Firebase/services/employees";
import {setEmployeeSliceData} from "../../store/employee.slice";
import {EyeInvisibleOutlined} from "@ant-design/icons";

const ManageEmployees = () => {
    const refetchTableDataRef = useRef(null)
    const [isFetching, setIsFetching] = useState(true)
    const {data: rdxEmployeeSliceData} = useSelector(state => state.employee)   // agency data in store that's being viewed
    const dispatch = useDispatch()
    const urlParams = useParams();

    useEffect(() => {
        if (urlParams && rdxEmployeeSliceData === null) {
            getEmployeeDetails(urlParams.id)
                .then((data) => {
                    dispatch(setEmployeeSliceData(data))
                })
                .finally(() => {
                    setIsFetching(false)
                })
        } else {
            setIsFetching(false)
        }
    }, []);

    return <>
        {
            isFetching
                ? <Spin className={"grid place-items-center h-full"}/>
                : <main className='grid grid-cols-12 gap-x-4'>
                    <div className="grid grid-rows-3 col-span-2 gap-y-2">
                        <Card title="Employee Details">
                            <p>Name: {rdxEmployeeSliceData["candidate"]["name"]}</p>
                            <p>Email: {rdxEmployeeSliceData["candidate"]["email"].slice(0, 17)}...</p>
                            <p className={"flex justify-between items-center"}>
                                SSN: XXXX
                                <EyeInvisibleOutlined/>
                            </p>
                        </Card>
                        <Card title="Project Details">
                            <p>End Client: {rdxEmployeeSliceData["endClientName"]}</p>
                            <p>Start Date: {dayjs(rdxEmployeeSliceData["projectStartDate"]).format("MM/DD/YYYY")}</p>
                            <p>Reporting: {rdxEmployeeSliceData["reportingManager"]}</p>
                        </Card>
                        <Card title="Billing Details">
                            <p>Rate: ${rdxEmployeeSliceData["rate"]}/Hr</p>
                            <p>Invoice Terms: {rdxEmployeeSliceData["invoiceTerms"]}</p>
                            <p>Payment Terms: {rdxEmployeeSliceData["paymentTerms"]}</p>
                        </Card>
                    </div>
                    <Card
                        title={(
                            <div className="flex flex-row justify-between items-center tab-no-children">
                                Timesheet
                                <TimeSheetDrawer
                                    type={"add"}
                                    reFetchData={refetchTableDataRef.current !== null ? () => refetchTableDataRef.current() : () => null}
                                />
                            </div>
                        )}
                        className='col-span-10'
                    >
                        <TimeSheetsTable refetchTableDataRef={refetchTableDataRef}/>
                    </Card>
                </main>
        }
    </>
};

export default ManageEmployees;