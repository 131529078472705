import React from 'react';
import {DatePicker, Form, Input} from 'antd';
import {_replaceUndefinedKeys} from "../utils";

const {TextArea} = Input;

const CandidateForm = (props) => {

    return (
        <Form
            form={props.form}
            name="candidate_form"
            initialValues={{
                remember: true,
            }}
            onFinish={(values) => {
                if (values.dob) {
                    props.onFormSubmit({
                        ..._replaceUndefinedKeys(values),
                        dob: values.dob.toISOString()
                    })
                } else {
                    props.onFormSubmit(_replaceUndefinedKeys(values))
                }
            }}
            layout='vertical'
            className='grid grid-cols-2 gap-x-2'
        >

            <Form.Item
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please input your name!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                    {
                        type: 'email',
                        message: 'Please enter a valid email address!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Phone"
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Please input your phone number!',
                    },
                    {
                        pattern: /^[0-9]{10}$/,
                        message: 'Please enter a valid phone number!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="SSN"
                name="ssn"
                rules={[
                    {
                        len: 9,
                        message: 'Please enter exactly 9 digits!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="On Corp"
                name="candidateOnCompany"
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="DOB" name="dob"
            >
                <DatePicker format={"MM/DD/YYYY"}/>
            </Form.Item>
            <Form.Item label="Address" name="address"
                       className='col-span-2'
            >
                <TextArea rows={2}/>
            </Form.Item>

            <Form.Item
                label="Remarks"
                name="remarks"
                className='col-span-2'
            >
                <TextArea rows={4}/>
            </Form.Item>
        </Form>
    );
};

export default CandidateForm;
