import {db} from '../index';
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where} from "firebase/firestore";

export const createTimesheet = async (docData, cb) => {
    try {
        const docRef = await addDoc(collection(db, 'timesheets'), docData);
        cb && cb()
        return docRef.id;
    } catch (error) {
        console.error('Error creating timesheet:', error);
        throw new Error('Failed to create timesheet');
    }
};

export const updateTimesheet = async (docID, updatedData, cb) => {
    try {
        await updateDoc(doc(db, 'timesheets', docID), updatedData);
        cb && cb()
    } catch (error) {
        console.error('Error updating timesheet:', error);
        throw new Error('Failed to update timesheet');
    }
};

export const deleteTimesheet = async (docID) => {
    try {
        await deleteDoc(doc(db, 'timesheets', docID));
    } catch (error) {
        console.error('Error deleting timesheet:', error);
        throw new Error('Failed to delete timesheet');
    }
};

export const getTimesheet = async (docID) => {
    try {
        const docRef = doc(db, 'timesheets', docID);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            return {id: docID, ...docSnapshot.data()};
        } else {
            throw new Error('Timesheet not found');
        }
    } catch (error) {
        console.error('Error fetching timesheet details:', error);
        throw new Error('Failed to fetch timesheet details');
    }
};

export const getAllTimesheets = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'timesheets'));
        const timesheets = [];
        querySnapshot.forEach((doc) => {
            timesheets.push({id: doc.id, ...doc.data()});
        });
        return timesheets;
    } catch (error) {
        console.error('Error fetching timesheets:', error);
        throw new Error('Failed to fetch timesheets');
    }
};

export const getAllTimeSheetsForEmployee = async (employeeRefId) => {
    try {
        const q = query(
            collection(db, "timesheets"),
            where("employeeRefId", "==", employeeRefId)
        );
        const querySnapshot = await getDocs(q);
        const agencies = [];

        for (const doc of querySnapshot.docs) {
            let docData = {id: doc.id, ...doc.data()};
            agencies.push(docData);
        }
        return Promise.resolve(agencies);
    } catch (error) {
        return Promise.reject(error)
    }
};
