import React, {useState} from 'react';
import {_Services} from "../services";
import {Button, Drawer, notification} from "antd";

const PdfDrawerComponents = ({data, reFetchData}) => {
    const {fileUrl} = data;
    const [open, setOpen] = useState(false);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)

    const showDrawer = (force) => {
        let config = {type: "success", message: `Timesheet ${force ? "updated" : "created"} successfully`}

        if (fileUrl && force === false) {
            setOpen(true);
        } else {
            setIsGeneratingPdf(true)
            _Services.generatePdf(data.id)
                .catch((error) => {
                    config = {
                        type: "error", message: "Timesheet couldn't be created. Please try again"
                    }
                    console.error(error)
                })
                .finally(() => {
                    setIsGeneratingPdf(false)
                    notification[config.type]({message: config.message})
                    if (config.type === "success") {
                        reFetchData()
                    }
                })
        }
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
           <span className="flex gap-2 ml-auto">
                <Button type="primary" onClick={() => showDrawer(fileUrl)} loading={isGeneratingPdf}>
                {fileUrl ? "Generate New PDF" : "Generate PDF"}
            </Button>
               {
                   fileUrl &&
                   <Button type="primary" onClick={() => showDrawer(false)} className={"ml-auto"}>
                       View PDF
                   </Button>
               }
           </span>
            <Drawer width={"50%"} title={!fileUrl ? "Generate PDF" : "View PDF"} onClose={onClose} open={open}>
                <embed
                    src={fileUrl}
                    className={"h-full w-full"}
                />
            </Drawer>
        </>
    )
}


export default PdfDrawerComponents;