import React, {useState} from 'react';
import {Button, Form, Modal, notification} from 'antd';
import CandidateForm from "./CandidateForm.component"
import {createCandidate, updateCandidate} from '../Firebase/services/candidates';
import {isoStringToDayJs} from "../utils";

const CandidateModal = ({type, formData, reFetchData, employee}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    let label = employee ? "Employee" : "Candidate"

    let configProps = {
        btnTitle: "+ Add " + label,
        modalTitle: "Add " + label,
        onFormSubmit: (values) => {
            setLoading(true)
            let config = {type: "success", message: "Employee added successfully"}
            createCandidate(values)
                .catch((error) => {
                    config = {
                        type: "error", message: "Employee couldn't be created. Please try again"
                    }
                    console.error(error)
                })
                .finally(() => {
                    notification[config.type]({message: config.message})
                    if (config.type === "success") {
                        reFetchData && reFetchData()
                        handleCancel(true)
                    }
                })
        }
    }

    if (type === 'edit') {

        configProps = {
            ...configProps,
            btnTitle: "Edit " + label,
            modalTitle: "Edit " + label,
            formData: formData,
            onFormSubmit: (values) => {
                setLoading(true)
                let config = {type: "success", message: label + " updated successfully"}
                updateCandidate(formData.id, values)
                    .catch((err) => {
                        config = {
                            type: "error", message: label + " couldn't be updated. Please try again"
                        }
                        console.error(err)
                    })
                    .finally(() => {
                        setLoading(false)
                        notification[config.type]({message: config.message})
                        if (config.type === "success") {
                            handleCancel(true)
                        }
                    })
            },
        }
    }
    const showModal = () => {
        if (type === "edit") {
            let {dob: dobISOString, ...data} = formData
            let dob = isoStringToDayJs(dobISOString);
            form.setFieldsValue({...data, dob})
        }
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
    };

    const handleCancel = (refetch = false) => {
        setIsModalOpen(false);
        form.resetFields()
        if (refetch) {
            reFetchData()
        }
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                {configProps.btnTitle}
            </Button>
            <Modal
                title={configProps.modalTitle}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{loading}}
                centered width={"50%"}
            >
                <CandidateForm
                    form={form}
                    onFormSubmit={configProps.onFormSubmit}
                />
            </Modal>
        </>
    );
};
export default CandidateModal;

CandidateModal.defaultProps = {
    type: "add",
    formData: null,
    employee: true
}