import React, {useState} from 'react';
import {Button, Popover, Typography} from 'antd';

const ConfirmationPopover = ({children, onOk, onCancel, ...props}) => {
    const [open, setOpen] = useState(false);
    const hide = () => {
        onCancel && onCancel();
        setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const handleOnOk = ()=>{
        setOpen(false)
        onOk && onOk()
    }
    return (
        <Popover
            {...props}
            content={
                <div className={"flex gap-2 items-center"}>
                    <Button onClick={handleOnOk} type={"primary"}>Ok</Button>
                    <Button onClick={hide} danger>Cancel</Button>
                </div>
            }
            title={
                <Typography.Text>Are you sure?</Typography.Text>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            {children}
        </Popover>
    );
};
export default ConfirmationPopover;